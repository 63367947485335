import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
// import Image from "../components/image"
import Img from "gatsby-image"
import SomeSiteMetadata from "../../components/SiteMetadata"



class Form extends React.Component {

  constructor() {
    super();
    this.submitform = this.submitform.bind(this);
    this.state = {
      className: 'alert alert-info invisible',
      responseMessage: ''
    }
  }


  render() {
    return (
      <div>
        <form id="js-form" action="#" method="POST">
          <div className="field">
            <label className="label" htmlFor="name">Name</label>
            <div className="control">
              <input className="input" name="name" type="text" placeholder="Name..." />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="reply_to">email</label>
            <div className="control">
              <input className="input" name="reply_to" type="email" placeholder="your_email@address.com" />
            </div>
          </div>
          <div className="field verify">
            <label className="label" htmlFor="verify">verify email</label>
            <div className="control">
              <input className="input" name="verify" type="email" placeholder="your_email@address.com" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="phone">Phone</label>
            <div className="control">
              <input className="input" name="phone" type="text" placeholder="+34..." />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="company">Company</label>
            <div className="control">
              <input className="input" name="company" type="text" placeholder="Company..." />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="message">Message</label>
            <div className="control">
              <textarea className="textarea" name="message" placeholder="Textarea"></textarea>
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button onClick={this.submitform} className="button is-black">Send</button>
            </div>
          </div>
        </form>
        <div className={this.state.className} role="alert"><p id="js-form-response">{this.state.responseMessage}</p></div>
      </div>
    );
  }

  submitform(e) {
    e.preventDefault();

    const form = e.target.form
    let className = this.state.className;
    let responseMessage = this.state.responseMessage;

    // Prepare data to send
    const formData = {};
    const formElements = Array.from(form);
    formElements.map(input => (formData[input.name] = input.value));

    // Log what our lambda function will receive
    console.log(JSON.stringify(formData));

    // Construct an HTTP request
    var xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action, true);
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    // Send the collected formData as JSON
    xhr.send(JSON.stringify(formData));

    // Callback function
    xhr.onloadend = response => {
      if (response.target.status === 200) {
        // The form submission was successful
        form.reset();
        className = "alert alert-info";
        responseMessage = "Thanks for the message. We'll be in touch shortly.";
      } else {
        // The form submission failed
        // alert.classList.remove("invisible");
        // alert.classList.remove("alert-info");
        // alert.classList.add("alert-danger");
        className = "alert alert-danger";
        responseMessage = 'Something went wrong';
        //console.error(JSON.parse(response.target.response).message);
      }
      this.setState({ className })
      this.setState({ responseMessage })
    };
  }
}



const IndexPage = ({ data }) => (

  <Layout>
    <SomeSiteMetadata title="Contact | Andre Buthe Equine Clinic Ltd" description="Contact Andre Buthe Equine Clinic" />
    <section className="section">
      <div className="container">
        <div className="content">
          
          <div className="columns">
            <div className="column is-5">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.8133508082337!2d-1.7237130840504544!3d51.42485522455588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48715b40e07313f5%3A0xe8fa7cd91f105c92!2sDr%20Andre%20Buthe%20Equine%20Clinic%20Ltd!5e0!3m2!1sen!2suk!4v1590844708961!5m2!1sen!2suk" width="100%" height="450" frameborder="0" style={{border:`0`}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
            <div className="column is-7">
              <h1>Contact Andre Buthe Equine Clinic</h1>
              <p><strong>Dr Andre Buthe Equine Clinic Ltd.</strong><br />
              The Mill House<br />
              Poulton Mill<br />
              Poulton<br />
              Marlborough<br />
              Wiltshire<br />
              SN8 2LN</p>

              <p><strong>Tel:</strong> +44 (0)1672 552882<br />
                <strong>Email:</strong> office@andrebuthe.com</p>

              <p><strong>Office hours</strong>: 9-12noon &amp; 2-5pm</p>
              <p><small>Dr Andre Buthe Equine Clinic Ltd is a company registered in England &amp; Wales with company number 07021105.
                    The registered office address is Carlton House, 101 New London Road, Chelmsford, Essex, CM2 0PP</small></p>
            </div>
          </div>
          {/* <Form /> */}
        </div>
      </div>
    </section>
  </Layout>


)


export default IndexPage
